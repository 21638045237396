import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FilterPanel } from './FilterPanel';
import {
  setEnvironment,
  setFilterOnlyClassesA,
  setFilterWorkingGames,
  setGameLanguage,
  setService,
  setSortingGamesByClass,
} from 'redux/actions/setUI';
import { setCurrentCurrency } from 'redux/actions/setCurrentSettings';

export const FilterPanelContainer = ({ isLoading }) => {
  const dispatch = useDispatch();

  const isOnlyWorkingGames = useSelector(
    (state) => state.stateUI.onlyWorkingGames
  );
  const isOnlyClassesA = useSelector((state) => state.stateUI.onlyClassesA);
  const typeSortingByClass = useSelector(
    (state) => state.stateUI.typeSortingByClass
  );
  const service = useSelector((state) => state.stateUI.service);
  const environment = useSelector((state) => state.stateUI.environment);
  const language = useSelector((state) => state.stateUI.gameLanguage);
  const currency = useSelector((state) => state.currentSettings.currency);
  const role = useSelector((state) => state?.user?.player?.role?.name);

  const isAdmin = role === 'admin';

  const handleStatusGame = (event) => {
    dispatch(setFilterWorkingGames(event.target.checked));
  };

  const handleTypeClass = (event) => {
    dispatch(setFilterOnlyClassesA(event.target.checked));
  };

  const handleSorting = (event) => {
    dispatch(setSortingGamesByClass(event.target.value));
  };

  const handleChangeService = (event) => {
    dispatch(setService(event.target.value));
  };

  const handleChangeEnvironment = (event) => {
    dispatch(setEnvironment(event.target.value));
  };

  const handleChangeLanguage = (event) => {
    dispatch(setGameLanguage(event.target.value));
  };

  const handleChangeCurrency = (event) => {
    dispatch(setCurrentCurrency(event.target.value));
  };

  return (
    <FilterPanel
      isOnlyWorkingGames={isOnlyWorkingGames}
      handleStatusGame={handleStatusGame}
      isOnlyClassesA={isOnlyClassesA}
      handleTypeClass={handleTypeClass}
      typeSortingByClass={typeSortingByClass}
      handleSorting={handleSorting}
      service={service}
      handleChangeService={handleChangeService}
      environment={environment}
      handleChangeEnvironment={handleChangeEnvironment}
      language={language}
      handleChangeLanguage={handleChangeLanguage}
      currency={currency}
      handleChangeCurrency={handleChangeCurrency}
      isLoading={isLoading}
      isAdmin={isAdmin}
    />
  );
};

FilterPanelContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
