import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import PlayerService from 'services/PlayerService';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material';

import styles from './PasswordResetForm.module.scss';

export const PasswordResetForm = (props) => {
  const { isOpenPasswordForm, setIsOpenPasswordForm, dataPlayer } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return props.dataPlayer;
    }, [props]),
  });

  useEffect(() => {
    reset(props.dataPlayer);
  }, [props.dataPlayer, reset]);

  const handleClose = () => {
    setIsOpenPasswordForm(false);
  };

  const onSubmitEditingPlayer = async (data) => {
    try {
      await PlayerService.changePlayer(data);
      setOpenAlert(true);
      setSeverity('success');
      setResponseMessage('password successfully changed');
      handleClose();
      reset();
    } catch (error) {
      setOpenAlert(true);
      setSeverity('error');
      setResponseMessage(error.message);
      console.error(error.message);
    }
  };

  return (
    <>
      <Dialog className={styles.passwordDialog} open={isOpenPasswordForm} onClose={handleClose}>
        <DialogTitle className={styles.passwordDialogTitle}>Set password: {dataPlayer.nickname}</DialogTitle>
        <DialogContent className={styles.passwordDialogContent} sx={{ paddingBottom: '5px' }}>
          <form onSubmit={handleSubmit(onSubmitEditingPlayer)}>
            <div className={styles.passwordForm}>
              <div className={styles.fieldForm}>
                <TextField
                  label="Password"
                  variant="standard"
                  className={styles.passwordInput}
                  placeholder="Password"
                  autoFocus
                  type="password"
                  {...register('password', { required: 'Reqiured field' })}
                  error={!!errors.password}
                  helperText={errors?.password ? errors.password.message : null}
                />
              </div>
              <div className={styles.fieldForm}>
                <TextField
                  label="Confirm"
                  variant="standard"
                  type="password"
                  className={styles.passwordInput}
                  placeholder="Confirm"
                  {...register('confirm', { required: 'Reqiured field' })}
                  error={!!errors.password}
                  helperText={errors?.password ? errors.password.message : null}
                />
              </div>
            </div>

            <DialogActions className={styles.buttonsGroup}>
              <Button type="submit">Update</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
          {responseMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

PasswordResetForm.propTypes = {
  isOpenPasswordForm: PropTypes.bool.isRequired,
  setIsOpenPasswordForm: PropTypes.func.isRequired,
  dataPlayer: PropTypes.object.isRequired,
};
