import PropTypes from 'prop-types';
import { Button, Checkbox, InputAdornment, styled, TableCell, tableCellClasses } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import styles from './playersTable.module.scss';
import { CURRENCY } from 'consts/ApiParametrs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const PlayersTable = ({
  players,
  handleClickOpenCreditForm,
  changePlayer,
  changePassword,
  handleClickRemovePlayer,
  currencyIcon,
  currency,
  openPlayerForm,
  isAdmin,
}) => {
  // eslint-disable-next-line array-callback-return
  const sortedPlayers = players.sort((prev, next) => {
    if (prev.nickname < next.nickname) return -1;
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className={styles.tableHead}>
            <TableRow>
              <StyledTableCell>Nickname</StyledTableCell>
              <StyledTableCell align="center">First name</StyledTableCell>
              <StyledTableCell align="center">Role</StyledTableCell>
              <StyledTableCell align="center">Credit(real)</StyledTableCell>
              <StyledTableCell align="center">Credit(fun)</StyledTableCell>
              <StyledTableCell align="center">Active</StyledTableCell>
              <StyledTableCell align="center">
                {isAdmin && (
                  <Button className={styles.button} variant="contained" onClick={openPlayerForm}>
                    Add new player
                  </Button>
                )}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPlayers.map((player) => (
              <StyledTableRow key={player.nickname}>
                <StyledTableCell component="th" scope="row">
                  {player.nickname}
                </StyledTableCell>
                <StyledTableCell align="center">{player.firstName}</StyledTableCell>
                <StyledTableCell align="center">{player.role.name}</StyledTableCell>
                <StyledTableCell align="center">
                  <button onClick={(e) => handleClickOpenCreditForm(player)} className={styles.creditButton}>
                    {currency !== CURRENCY.RUB ? (player.balance.real / 100).toFixed(2) : player.balance.real}
                    <InputAdornment position="end">{currencyIcon}</InputAdornment>
                  </button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <button onClick={(e) => handleClickOpenCreditForm(player)} className={styles.creditButton}>
                    {currency !== CURRENCY.RUB ? (player.balance.fun / 100).toFixed(2) : player.balance.fun}
                    <InputAdornment position="end">{currencyIcon}</InputAdornment>
                  </button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Checkbox
                    checked={player.active}
                    disabled
                    className={styles.checkbox}
                    inputProps={{
                      'aria-label': 'player status',
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell align="center">
                  <div className={styles.btnGroup}>
                    <Button
                      variant="contained"
                      size="small"
                      className={styles.button}
                      onClick={() => {
                        changePlayer(player.nickname);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className={styles.button}
                      onClick={() => {
                        changePassword(player.nickname);
                      }}
                    >
                      RESET PASSWORD
                    </Button>
                    {isAdmin && (
                      <Button
                        className={styles.button}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleClickRemovePlayer(player.nickname);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

PlayersTable.propTypes = {
  players: PropTypes.array.isRequired,
  handleClickOpenCreditForm: PropTypes.func.isRequired,
  changePlayer: PropTypes.func.isRequired,
  handleClickRemovePlayer: PropTypes.func.isRequired,
  currencyIcon: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};
