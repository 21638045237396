import {
  SET_ALL_DEMO_GAMES,
  SET_DEMO_GAMES,
  ADD_ACTIVE_GAME,
  REMOVE_ACTIVE_GAME,
  CHANGE_VERSION_DEMO_GAME,
  CHANGE_SERVICE_DEMO_GAME,
  CHANGE_ENVIRONMENT_DEMO_GAME,
} from '../actions/actionTypes';

const initialState = {
  displayedDemoGames: [],
  games: [],
  activeGames: [],
};

export const demoGamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEMO_GAMES:
      return {
        ...state,
        displayedDemoGames: action.payload,
      };

    case SET_ALL_DEMO_GAMES:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_ACTIVE_GAME:
      return {
        ...state,
        activeGames: [...state.activeGames, action.payload],
      };

    case REMOVE_ACTIVE_GAME:
      const gameClassIndex = state.activeGames.findIndex((game) => game.settings.gameClass === action.payload);
      return {
        ...state,
        activeGames: [...state.activeGames.slice(0, gameClassIndex), ...state.activeGames.slice(gameClassIndex + 1)],
      };

    case CHANGE_VERSION_DEMO_GAME:
      const gamesWithNewVersion = state.activeGames.map((game) =>
        game.settings.gameClass === action.payload.gameClass
          ? { ...game, settings: { ...game.settings, version: action.payload.version } }
          : game
      );

      return {
        ...state,
        activeGames: gamesWithNewVersion,
      };

    case CHANGE_SERVICE_DEMO_GAME:
      const gamesWithNewService = state.activeGames.map((game) =>
        game.settings.gameClass === action.payload.gameClass
          ? { ...game, settings: { ...game.settings, backend: action.payload.service } }
          : game
      );

      return {
        ...state,
        activeGames: gamesWithNewService,
      };

    case CHANGE_ENVIRONMENT_DEMO_GAME:
      const gamesWithNewEnvironment = state.activeGames.map((game) =>
        game.settings.gameClass === action.payload.gameClass
          ? { ...game, settings: { ...game.settings, launchEnv: action.payload.environment } }
          : game
      );

      return {
        ...state,
        activeGames: gamesWithNewEnvironment,
      };

    default:
      return state;
  }
};
