import PropTypes from 'prop-types';
import { AccountCircle, Logout } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AppBar, Container, IconButton, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

import styles from './header.module.scss';
import { CURRENCY } from 'consts/ApiParametrs';

export const Header = (props) => {
  const {
    isAuth,
    userName,
    creditFun,
    creditReal,
    handleLogout,
    closeAdminPanel,
    handleClickOpenCreditForm,
    currencyIcon,
    currency,
    role,
    currentPage,
    permissions,
  } = props;

  let moneyFun = creditFun;
  let moneyReal = creditReal;

  const isAllowAdminPage = role !== 'demo';
  const isAllowDemoPage = permissions?.includes('demo_page');

  if (currency !== CURRENCY.RUB) {
    moneyFun = (creditFun / 100).toFixed(2);
    moneyReal = (creditReal / 100).toFixed(2);
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Container
          className={styles.header}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          maxWidth={'xl'}
        >
          <Link to="/" onClick={() => closeAdminPanel()}>
            <img src="/img/logo-octavian-horizontal.svg" alt="Octavian games" className={styles.logo} />
          </Link>
          {(currentPage === 'main' || currentPage === 'admin') && isAllowDemoPage && (
            <div className={styles.demo}>
              <Link className={styles.demoLink} to="/demo">
                DEMO
              </Link>
            </div>
          )}
          {!isAuth && (
            <IconButton color="inherit" className={styles.iconBtn}>
              <Link to="/login">
                <AccountCircle
                  sx={{
                    height: '2rem',
                    width: '2rem',
                    margin: '0.5rem',
                    color: 'white',
                  }}
                ></AccountCircle>
              </Link>
            </IconButton>
          )}
          {isAuth && (
            <div className={styles.interface}>
              {currentPage === 'main' && (
                <div className={styles.userInfo}>
                  <div className={styles.userName}>{userName}</div>
                  <div className={styles.credit}>
                    <button className={styles.creditButton} onClick={() => handleClickOpenCreditForm()}>
                      Credit Real: {moneyReal} {currencyIcon}
                    </button>
                    <button className={styles.creditButton} onClick={() => handleClickOpenCreditForm()}>
                      Credit Fun: {moneyFun} {currencyIcon}
                    </button>
                  </div>
                </div>
              )}

              <div className={styles.btnGroup}>
                {isAllowAdminPage && currentPage !== 'admin' && (
                  <Link to="/admin">
                    <ManageAccountsIcon className={styles.iconAdmin} aria-label="admin"></ManageAccountsIcon>
                  </Link>
                )}

                {isAllowAdminPage && currentPage === 'admin' && (
                  <Link to="/">
                    <ExitToAppIcon
                      className={styles.iconAdmin}
                      onClick={() => closeAdminPanel()}
                      aria-label="main"
                    ></ExitToAppIcon>
                  </Link>
                )}

                <IconButton color="inherit" onClick={() => handleLogout()} className={styles.iconBtn}>
                  <Logout className={styles.iconLogout} aria-label="logout" />
                </IconButton>
              </div>
            </div>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  creditFun: PropTypes.number,
  creditReal: PropTypes.number,
  handleLogout: PropTypes.func.isRequired,
  closeAdminPanel: PropTypes.func.isRequired,
  handleClickOpenCreditForm: PropTypes.func.isRequired,
  currencyIcon: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};
