import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TableCell,
  tableCellClasses,
  TextField,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';

import { SERVICE } from 'consts/ApiParametrs';
import { gameVersionsSorting } from 'utils/gameVersionsSorting';
import styles from './demoGameRow.module.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const DemoGameRow = ({
  game,
  settings,
  isActiveGame,
  changeListActiveGames,
  changeVersionOfActiveGame,
  changeServiceOfActiveGame,
  changeEnvironmentOfActiveGame,
}) => {
  const { gameName, gameClass, versions, release, gameIconUrl, latestVersion, environments } = game;

  const [version, setVersion] = useState('');
  const [service, setService] = useState('');
  const [environment, setEnvironment] = useState('');

  useEffect(() => {
    if (settings) {
      setVersion(settings.version);
      setService(settings.backend);
      setEnvironment(settings.launchEnv);
    } else if (latestVersion) {
      setVersion(latestVersion);
      setService(SERVICE.DMS);
      setEnvironment('dev');
    } else {
      setVersion(release.tag);
      setService(SERVICE.DMS);
      setEnvironment('dev');
    }
  }, [latestVersion, release, settings]);

  const handleChangeVersionGame = (event) => {
    setVersion(event.target.value);
    if (isActiveGame(gameClass)) {
      const version = event.target.value;
      changeVersionOfActiveGame({ version, gameClass });
    }
  };

  const handleChangeService = (event) => {
    setService(event.target.value);
    if (isActiveGame(gameClass)) {
      const service = event.target.value;
      changeServiceOfActiveGame({ service, gameClass });
    }
  };

  const handleChangeEnvironment = (event) => {
    setEnvironment(event.target.value);
    if (isActiveGame(gameClass)) {
      const environment = event.target.value;
      changeEnvironmentOfActiveGame({ environment, gameClass });
    }
  };

  if (Array.isArray(versions)) {
    gameVersionsSorting(versions);
  }

  return (
    <StyledTableRow key={gameClass}>
      <StyledTableCell component="th" scope="row" sx={{ whiteSpace: 'nowrap' }}>
        {gameName}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" sx={{ whiteSpace: 'nowrap' }}>
        {gameClass}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
        {versions ? (
          <FormControl className={styles.selectGameVersion} size="small">
            <InputLabel htmlFor={`${gameClass}`} className={styles.versionLabel}>
              Game version
            </InputLabel>
            <Select
              inputProps={{ id: `${gameClass} ${version}` }}
              value={version}
              label="Game version"
              onChange={handleChangeVersionGame}
              sx={{ textAlign: 'left' }}
            >
              <MenuItem value={'last'}>last</MenuItem>
              {versions.map((version) => {
                return (
                  <MenuItem key={version.name} value={version.name}>
                    {version.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : release ? (
          <TextField
            className={styles.fieldGameVersion}
            size="small"
            id="outlined-basic"
            label="Game version"
            variant="outlined"
            value={version}
          />
        ) : (
          <></>
        )}
      </StyledTableCell>

      <StyledTableCell align="center">
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="service-label">Service</InputLabel>
          <Select inputProps={{ id: 'service-label' }} label="Service" value={service} onChange={handleChangeService}>
            <MenuItem value={SERVICE.LCS}>LCS</MenuItem>
            <MenuItem value={SERVICE.DMS}>DMS</MenuItem>
          </Select>
        </FormControl>
      </StyledTableCell>

      <StyledTableCell align="center">
        <FormControl className={styles.selectEnvironment} variant="outlined" size="small">
          <InputLabel htmlFor="environment-label">Environment</InputLabel>
          <Select
            inputProps={{ id: 'environment-label' }}
            label="Environment"
            value={environment}
            onChange={handleChangeEnvironment}
          >
            {environments.map((env, id) => {
              return (
                <MenuItem key={id} value={env}>
                  {env}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </StyledTableCell>

      <StyledTableCell align="center">
        <img className={styles.img} alt={gameName} src={gameIconUrl}></img>
      </StyledTableCell>

      <StyledTableCell align="center">
        <Checkbox
          checked={isActiveGame(gameClass)}
          onChange={() => changeListActiveGames({ game, version, service, environment })}
          inputProps={{
            'aria-label': 'game display state',
          }}
        />
      </StyledTableCell>
      <StyledTableCell align="center"></StyledTableCell>
    </StyledTableRow>
  );
};

DemoGameRow.propTypes = {
  game: PropTypes.object,
  isActiveGame: PropTypes.func.isRequired,
  changeListActiveGames: PropTypes.func.isRequired,
  changeVersionOfActiveGame: PropTypes.func.isRequired,
  changeServiceOfActiveGame: PropTypes.func.isRequired,
  changeEnvironmentOfActiveGame: PropTypes.func.isRequired,
};
