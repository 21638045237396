import { setDemoGames } from 'redux/actions/setDemoGames'
import GameService from 'services/GameService'

export function fetchDemoGames(games = null) {
    return async (dispatch) => {
        try {
            const response = await GameService.getDemoGames()

            if (!games || JSON.stringify(response.data.data.games) !== JSON.stringify(games)) {
                dispatch(setDemoGames(response.data.data.games))
            }
        } catch (error) {
            console.error(error.message)
        }
    }
}
