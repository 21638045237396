import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { Preloader } from '../Preloader/Preloader'
import { FilterPanelContainer } from 'components/Main/FilterPanel/FilterPanelContainer'
import { Games } from './Games/Games'
import { Search } from './Search/Search'
import { GameSettingInterfaceContainer } from './GameSettingInterface/GameSettingInterfaceContainer'

export const Main = ({
    games,
    environment,
    isLoading,
    runGame,
    selectedCurrency,
    selectedLanguage,
    service,
    isOnlyWorkingGames,
    isOnlyClassesA,
    typeSortingByClass,
    isMobile,
}) => {
    const [filteredGames, setFilteredGames] = useState([])

    const applyStatusFilter = useCallback(() => {
        if (isOnlyWorkingGames) {
            setFilteredGames(games.filter((game) => game.gameStatus === 'OK'))
        } else {
            setFilteredGames(games)
        }
    }, [games, isOnlyWorkingGames])

    const applyTypeFilter = (games) => {
        let filteredGames

        if (isOnlyClassesA) {
            filteredGames = games.filter((game) => game.gameClass[game.gameClass.length - 1] === 'a')
            return filteredGames
        } else {
            return games
        }
    }

    useEffect(() => {
        setFilteredGames(games)
        applyStatusFilter()
    }, [games, isOnlyWorkingGames, applyStatusFilter])

    const handleSearch = (nameGame) => {
        setFilteredGames(getFilteredGamesByName(nameGame))
    }

    const getFilteredGamesByName = (nameGame) => {
        let filteredGames
        const filteredGamesHasName = games.filter((game) => game.gameName)

        const filteredGamesByName = filteredGamesHasName.filter((game) =>
            game.gameName.toLowerCase().includes(nameGame.toLowerCase())
        )
        if (isOnlyWorkingGames) {
            filteredGames = filteredGamesByName.filter((game) => game.gameStatus === 'OK')
        } else {
            if (nameGame) {
                filteredGames = filteredGamesByName
            } else {
                filteredGames = games
            }
        }
        return filteredGames
    }

    const sorting = (games) => {
        switch (typeSortingByClass) {
            case 'to-largest':
                return games.sort((a, b) => {
                    if (a.gameClass < b.gameClass) {
                        return -1
                    }
                    if (a.gameClass > b.gameClass) {
                        return 1
                    }
                    return 0
                })

            case 'to-smallest':
                return games.sort((a, b) => {
                    if (a.gameClass < b.gameClass) {
                        return 1
                    }
                    if (a.gameClass > b.gameClass) {
                        return -1
                    }
                    return 0
                })
            default:
                return games
        }
    }

    return (
        <main className="main">
            <Search handleSearch={handleSearch} isLoading={isLoading}></Search>
            <FilterPanelContainer isLoading={isLoading} />
            <GameSettingInterfaceContainer isLoading={isLoading}></GameSettingInterfaceContainer>

            {isLoading ? (
                <Preloader />
            ) : (
                <Games
                    games={applyTypeFilter(sorting(filteredGames))}
                    environment={environment}
                    runGame={runGame}
                    selectedCurrency={selectedCurrency}
                    selectedLanguage={selectedLanguage}
                    service={service}
                />
            )}
        </main>
    )
}

Main.propTypes = {
    games: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    environment: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    runGame: PropTypes.func.isRequired,
    selectedCurrency: PropTypes.string.isRequired,
    selectedLanguage: PropTypes.string.isRequired,
    service: PropTypes.string,
    isOnlyWorkingGames: PropTypes.bool.isRequired,
    isOnlyClassesA: PropTypes.bool.isRequired,
    typeSortingByClass: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
}
