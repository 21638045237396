import PropTypes from 'prop-types';
import styles from './errorFallback.module.scss';

export function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div role="alert" className={styles.errorFallback}>
      <p>Something went wrong</p>
    </div>
  );
}

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};
