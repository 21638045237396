const { HeaderContainer } = require('components/Header/HeaderContainer');
const { Outlet } = require('react-router-dom');

export const MainLayout = () => {
  return (
    <>
      <HeaderContainer></HeaderContainer>
      <Outlet></Outlet>
    </>
  );
};
