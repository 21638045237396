import $api from 'utils/http';

export default class AuthService {
  static async login(login, password) {
    return $api.get(`accounting/signin?nickname=${login}&password=${password}`);
  }

  static async auth() {
    return $api.get(`accounting/selfinfo`);
  }
}
