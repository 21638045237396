import { Container } from '@mui/material';
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { setGameUrl } from 'redux/actions/setGame';
import { MainContainer } from '../../Main/MainContainer';

export const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return function cleanupCurrentURL() {
      dispatch(setGameUrl(''));
    };
  });

  return (
    <Container sx={{ mt: '1rem' }} maxWidth={'xl'}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <MainContainer />
      </ErrorBoundary>
    </Container>
  );
};
