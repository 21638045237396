import axios from 'axios';

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}accounting/refresh?refresh_token=${localStorage.getItem(
            'tokenR'
          )}`
        );
        localStorage.setItem('token', response.data.security.access_token);
        return $api.request(originalRequest);
      } catch (e) {
        console.error(e);
      }
    }
    throw error;
  }
);

export default $api;
