import { SET_CURRENT_PAGE } from '../actions/actionTypes';

const initialState = {
  currentPage: '',
};

export const currentStateUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    default:
      return state;
  }
};
