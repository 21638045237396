import { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "redux/actions/setAuth";
import { setCurrentPage } from "redux/actions/setCurrentUI";
import { isMobile } from "react-device-detect";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Logout } from "@mui/icons-material";
import { Container, IconButton } from "@mui/material";
import { DemoGamesContainer } from "./DemoGamesContainer";

import styles from "./demoPage.module.scss";
import { useEffect } from "react";
import { setGameUrl } from "redux/actions/setGame";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components/ErrorFallback/ErrorFallback";

export const DemoPage = () => {
  const dispatch = useDispatch();

  let [isFullScreen, setFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    if (!isFullScreen && isMobile) {
      handle.enter();
      setFullScreen(true);
    }
  };

  const handleLogout = () => {
    handle.exit();
    setFullScreen(false);
    dispatch(logout());
    dispatch(setCurrentPage(""));
  };

  useEffect(() => {
    return function cleanupCurrentURL() {
      dispatch(setGameUrl(""));
    };
  });

  useEffect(() => {
    if (!handle.active) {
      setFullScreen(false);
    }
  }, [handle]);

  useEffect(() => {
    function handleTouchMove(event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }

    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  return (
    <FullScreen handle={handle} className={styles.fullscreen}>
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0, color: "transparent" }}
        color="inherit"
        onClick={handleLogout}
        className={styles.iconBtn}
      >
        <Logout sx={{ width: "50px", height: "50px" }} aria-label="logout" />
      </IconButton>
      <div onClick={handleFullScreen}>
        <Container sx={{ pt: "1rem", textAlign: "center", minHeight: "100vh" }} maxWidth={false}>
          {/* <img src="/img/logo-demo-page.png" alt="Octavian game" className={styles.logoDemo} /> */}
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <DemoGamesContainer />
          </ErrorBoundary>
        </Container>
      </div>
    </FullScreen>
  );
};
