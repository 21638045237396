import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Game } from '../Game/Game';

export const Games = ({
  games,
  environment,
  runGame,
  selectedCurrency,
  selectedLanguage,
  service,
}) => {
  return (
    <Grid container sx={{ margin: 0 }}>
      {games.map((game) => {
        return (
          <Game
            key={game.gameClass}
            {...game}
            environment={environment}
            runGame={runGame}
            selectedCurrency={selectedCurrency}
            selectedLanguage={selectedLanguage}
            service={service}
          />
        );
      })}
    </Grid>
  );
};

Games.propTypes = {
  games: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  environment: PropTypes.string.isRequired,
  runGame: PropTypes.func.isRequired,
  selectedCurrency: PropTypes.string.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  service: PropTypes.string,
};
