export const gameVersionsSorting = (versions) => {
  versions.sort(function (a, b) {
    let arrNumberStringsA = a.name.match(/[0-9,.]+/g);
    let arrNumberStringsB = b.name.match(/[0-9,.]+/g);

    for (let i = 0; i < arrNumberStringsA.length && i < arrNumberStringsB.length; i++) {
      let result;
      const arrNumbersA = arrNumberStringsA[i].split('.');
      const arrNumbersB = arrNumberStringsB[i].split('.');

      while (arrNumbersA.length) {
        if ((result = arrNumbersA.shift() - (arrNumbersB.shift() || 0))) {
          return -result;
        }
      }

      if (arrNumbersA.length === 0 && arrNumbersB.length === 0) {
        continue;
      }

      return arrNumbersB.length;
    }

    if (arrNumberStringsA.length >= arrNumberStringsB.length) {
      return -1;
    } else {
      return 1;
    }
  });
};
