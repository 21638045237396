import { setGameUrl } from 'redux/actions/setGame';
import GameService from 'services/GameService';

export function fetchUrlGame(props) {
  const { environment, gameClass, mode, language, currency, version, service, launchEnv } = props;
  return async (dispatch) => {
    try {
      const response = await GameService.getGameURL({
        environment,
        gameClass,
        mode,
        language,
        currency,
        version,
        service,
        launchEnv,
      });
      dispatch(setGameUrl(response.data.data.url));
    } catch (error) {
      console.error(error.message);
    }
  };
}
