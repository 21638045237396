import { SET_USER, LOGOUT } from '../actions/actionTypes';

const initialState = {
  isAuth: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
        isAuth: true,
      };

    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('tokenR');
      return {
        isAuth: false,
      };

    default:
      return state;
  }
};
