import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAdminPanel } from 'redux/actions/setAdminPanel'
import { updatePlayerInfo } from 'redux/actions/setAuth'
import { setGameUrl } from 'redux/actions/setGame'
import { fetchGames } from 'services/thunk/fetchGames'
import { fetchUrlGame } from 'services/thunk/fetchUrlGame'
import { Main } from './Main'
import { isMobile } from 'react-device-detect'
import { setCurrentPage } from 'redux/actions/setCurrentUI'
import { Iframe } from 'components/Iframe'
import { CreditForm } from 'components/players/CreditForm'

export const MainContainer = () => {
    const dispatch = useDispatch()
    const games = useSelector((state) => state.games?.games)
    const URLGame = useSelector((state) => state.currentGame.URL)
    const isOnlyWorkingGames = useSelector((state) => state.stateUI.onlyWorkingGames)
    const typeSortingByClass = useSelector((state) => state.stateUI.typeSortingByClass)
    const isOnlyClassesA = useSelector((state) => state.stateUI.onlyClassesA)
    const service = useSelector((state) => state.stateUI.service)
    const environmentUIState = useSelector((state) => state.stateUI.environment)
    const language = useSelector((state) => state.stateUI.gameLanguage)
    const currency = useSelector((state) => state.currentSettings.currency)
    const role = useSelector((state) => state?.user?.player?.role?.name)
    const isFullScreenGame = useSelector((state) => state.stateUI.fullScreenGame)
    const creditFun = useSelector((state) => state.user?.player?.balance?.fun)
    const creditReal = useSelector((state) => state.user?.player?.balance?.real)
    const userName = useSelector((state) => state.user.player?.nickname)
    const currentPage = useSelector((state) => state.currentStateUI.currentPage)

    const [isLoading, setIsLoading] = useState(true)
    const [isActiveIframe, setIsActiveIframe] = useState(false)
    const [isOpenCreditForm, setIsOpenCreditForm] = useState(false)
    const [balanceReal, setBalanceReal] = useState('')
    const [balanceFun, setBalanceFun] = useState('')
    const [nickname, setNickname] = useState('')

    const iframeRef = useRef(null)

    let environment

    switch (role) {
        case 'admin':
            environment = environmentUIState
            break
        case 'developer':
            environment = 'dev'
            break
        case 'certificator':
            environment = 'cert'
            break
        case 'stage_only':
            environment = 'stage'
            break
        default:
            break
    }

    useEffect(() => {
        setIsLoading(true)
        dispatch(fetchGames(environment)).then(() => {
            setIsLoading(false)
        })
        dispatch(setAdminPanel(false))
        dispatch(setCurrentPage('main'))
    }, [environment, dispatch])

    const runGame = ({ mode, gameClass, version }) => {
        dispatch(
            fetchUrlGame({
                environment,
                gameClass,
                mode,
                language,
                currency,
                version,
                service,
            })
        )
    }

    useEffect(() => {
        if (isFullScreenGame && URLGame) {
            window.open(URLGame, '_blank')
            dispatch(setGameUrl(''))
        } else if (URLGame) {
            setIsActiveIframe(true)
        }
    }, [dispatch, URLGame, isFullScreenGame])

    const closeModalIframe = (status) => {
        setIsActiveIframe(status)
        dispatch(updatePlayerInfo())
        dispatch(setGameUrl(''))
    }

    const handleClickOpenCreditForm = () => {
        setIsOpenCreditForm(true)
        setBalanceReal(creditReal)
        setBalanceFun(creditFun)
        setNickname(userName)
    }

    const sendMessageToIframe = () => {
        const message = { name: 'updateBalance' }
        const iframeWindow = iframeRef.current.contentWindow
        iframeWindow.postMessage(message, '*')
    }

    return (
        <main className="main">
            <Main
                games={games}
                environment={environment}
                isLoading={isLoading}
                runGame={runGame}
                selectedCurrency={currency}
                selectedLanguage={language}
                service={service}
                isOnlyWorkingGames={isOnlyWorkingGames}
                isOnlyClassesA={isOnlyClassesA}
                typeSortingByClass={typeSortingByClass}
                isMobile={isMobile}
            ></Main>
            {URLGame && isActiveIframe && (
                <div>
                    <Iframe
                        URLGame={URLGame}
                        closeModalIframe={closeModalIframe}
                        handleClickOpenCreditForm={handleClickOpenCreditForm}
                        isOpenCreditForm={isOpenCreditForm}
                        setIsOpenCreditForm={setIsOpenCreditForm}
                        balanceReal={balanceReal}
                        balanceFun={balanceFun}
                        nickname={nickname}
                        currency={currency}
                        currentPage={currentPage}
                        iframeRef={iframeRef}
                    />
                    <CreditForm
                        isOpenCreditForm={isOpenCreditForm}
                        setIsOpenCreditForm={setIsOpenCreditForm}
                        balanceReal={balanceReal}
                        balanceFun={balanceFun}
                        nickname={nickname}
                        currency={currency}
                        sendMessageToIframe={sendMessageToIframe}
                    ></CreditForm>
                </div>
            )}
        </main>
    )
}
