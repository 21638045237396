import $api from 'utils/http';

export default class PlayerService {
  static async getPlayers() {
    return $api.get(`${process.env.REACT_APP_API_URL}admin/players`);
  }

  static async addPlayer(data) {
    return $api.post(`${process.env.REACT_APP_API_URL}admin/player/add`, data);
  }

  static async changePlayer(data) {
    return $api.post(`${process.env.REACT_APP_API_URL}admin/player/details`, data);
  }

  static async removePlayer(data) {
    return $api.post(`${process.env.REACT_APP_API_URL}admin/player/delete`, data);
  }

  static async setBalance(data) {
    return $api.post(`${process.env.REACT_APP_API_URL}admin/player/setbalance`, data);
  }

  static async getBalance(data) {
    return $api.get(`${process.env.REACT_APP_API_URL}admin/player/getbalance?nickname=${data.nickname}`);
  }
}
