import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { DemoGame } from 'components/pages/demoPage/DemoGame/DemoGame'

const GAMES_ORDER = [
    'Book Of Mummy',
    'Buffalo Fury',
    'Helly Hot',
    'Rich Piggies',
    'Jungle Spirits',
    'Gold Pharaoh',
    'Dr Horror',
    'Voodoo Mystic',
    'White Wolf',
    'Octoberfest',
    'Honey Bees',
    'Thunder Gems',
    'God Of Olympus',
    'Scary Clown',
    'Fruits and Stars',
    'Chicken Farm',
    'Mystic Pharaoh',
    'Fortune God',
    'Rodeo Girls',
    'Gods Power',
    'Fireballs',
    'Bloody Vampire',
    "Devil's Heart",
    'Night at the Opera',
    'Vegas Mania',
]

export const DemoGames = ({ games, runDemoGame }) => {
    const findGameByName = (gameName) => games.find((game) => game.gameInfo.gameName === gameName)

    const sortedGames = GAMES_ORDER.map((gameName) => findGameByName(gameName)).filter(Boolean)

    const remainingUnsortedGames = games.filter((game) => !GAMES_ORDER.includes(game.gameInfo.gameName))
    const allGames = [...sortedGames, ...remainingUnsortedGames]

    return (
        <Grid container sx={{ margin: 0 }}>
            {allGames.map((game) => (
                <DemoGame key={game.settings.gameClass} {...game} runDemoGame={runDemoGame} />
            ))}
        </Grid>
    )
}

DemoGames.propTypes = {
    games: PropTypes.array.isRequired,
    runDemoGame: PropTypes.func.isRequired,
}
