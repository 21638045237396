import { CURRENCY } from 'consts/ApiParametrs'
import { SET_CURRENCY } from '../actions/actionTypes'

const initialState = {
    currencyIcon: '€',
    currency: CURRENCY.EUR,
}

const iconsCurrency = {
    EUR: '€',
    RUB: '₽',
    FUN: 'F',
    USD: '$',
}

export const currentSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENCY:
            return {
                ...state,
                currencyIcon: iconsCurrency[action.payload],
                currency: action.payload,
            }

        default:
            return state
    }
}
