import PropTypes from 'prop-types';

import { Button, styled, TableCell, tableCellClasses } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import styles from './demoGamesTable.module.scss';
import { Preloader } from 'components/Preloader/Preloader';
import { DemoGameRow } from './demoGameRow/DemoGameRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const DemoGamesTable = ({
  games,
  activeGames,
  changeListActiveGames,
  changeVersionOfActiveGame,
  changeServiceOfActiveGame,
  changeEnvironmentOfActiveGame,
  saveNewDemoGameSet,
}) => {
  if (games.length === 0) {
    return <Preloader />;
  }

  const isActiveGame = (gameClass) => {
    const gameClassesShownGames = activeGames.map((game) => game.settings.gameClass);
    return gameClassesShownGames.includes(gameClass);
  };

  // eslint-disable-next-line array-callback-return
  const sortedGames = games.sort((prev, next) => {
    if (prev.gameName < next.gameName) return -1;
  });

  const mixedGames = sortedGames.map((game) => {
    const activeGame = activeGames.find((shownGame) => shownGame.settings.gameClass === game.gameClass);
    return activeGame === undefined ? game : activeGame;
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className={styles.tableHead}>
            <TableRow>
              <StyledTableCell>Name game</StyledTableCell>
              <StyledTableCell>Game class</StyledTableCell>
              <StyledTableCell align="center">Game version</StyledTableCell>
              <StyledTableCell align="center">Service</StyledTableCell>
              <StyledTableCell align="center">Environment</StyledTableCell>
              <StyledTableCell align="center">Preloader</StyledTableCell>
              <StyledTableCell align="center">Show</StyledTableCell>
              <StyledTableCell align="center">
                <Button className={styles.button} variant="contained" onClick={saveNewDemoGameSet}>
                  SAVE
                </Button>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mixedGames.map((game) => {
              return game.gameInfo ? (
                <DemoGameRow
                  key={game.settings.gameClass}
                  game={game.gameInfo}
                  isActiveGame={isActiveGame}
                  changeListActiveGames={changeListActiveGames}
                  changeVersionOfActiveGame={changeVersionOfActiveGame}
                  changeServiceOfActiveGame={changeServiceOfActiveGame}
                  changeEnvironmentOfActiveGame={changeEnvironmentOfActiveGame}
                  settings={game.settings}
                ></DemoGameRow>
              ) : (
                <DemoGameRow
                  key={game.gameClass}
                  game={game}
                  isActiveGame={isActiveGame}
                  changeListActiveGames={changeListActiveGames}
                  changeVersionOfActiveGame={changeVersionOfActiveGame}
                  changeServiceOfActiveGame={changeServiceOfActiveGame}
                  changeEnvironmentOfActiveGame={changeEnvironmentOfActiveGame}
                ></DemoGameRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

DemoGamesTable.propTypes = {
  games: PropTypes.array,
  activeGames: PropTypes.array,
  changeListActiveGames: PropTypes.func.isRequired,
  saveNewDemoGameSet: PropTypes.func.isRequired,
  changeVersionOfActiveGame: PropTypes.func.isRequired,
  changeServiceOfActiveGame: PropTypes.func.isRequired,
  changeEnvironmentOfActiveGame: PropTypes.func.isRequired,
};
