import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setGameScreen } from 'redux/actions/setUI';
import { GameSettingInterface } from './GameSettingInterface';

export const GameSettingInterfaceContainer = ({ isLoading }) => {
  const isFullScreenGame = useSelector((state) => state.stateUI.fullScreenGame);
  const dispatch = useDispatch();

  const handleStatusFullScreen = (event) => {
    dispatch(setGameScreen(event.target.checked));
  };

  return (
    <GameSettingInterface
      isLoading={isLoading}
      handleStatusFullScreen={handleStatusFullScreen}
      isFullScreenGame={isFullScreenGame}
    ></GameSettingInterface>
  );
};

GameSettingInterfaceContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
