import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/setAuth';
import { CreditForm } from 'components/players/CreditForm';
import { setAdminPanel } from 'redux/actions/setAdminPanel';
import { Header } from './Header';
import { setCurrentPage } from 'redux/actions/setCurrentUI';

export const HeaderContainer = () => {
  const isAuth = useSelector((state) => state.user.isAuth);
  const creditFun = useSelector((state) => state.user?.player?.balance?.fun);
  const creditReal = useSelector((state) => state.user?.player?.balance?.real);
  const userName = useSelector((state) => state.user.player?.nickname);
  const currencyIcon = useSelector((state) => state.currentSettings.currencyIcon);
  const currency = useSelector((state) => state.currentSettings.currency);
  const role = useSelector((state) => state.user?.player?.role?.name);
  const permissions = useSelector((state) => state.user?.player?.role?.permissions);
  const currentPage = useSelector((state) => state.currentStateUI.currentPage);

  const dispatch = useDispatch();

  const [isOpenCreditForm, setIsOpenCreditForm] = useState(false);
  const [balanceReal, setBalanceReal] = useState('');
  const [balanceFun, setBalanceFun] = useState('');
  const [nickname, setNickname] = useState('');

  const handleLogout = () => {
    dispatch(logout());
    dispatch(setCurrentPage(''));
  };

  const closeAdminPanel = () => {
    dispatch(setAdminPanel(false));
  };

  const handleClickOpenCreditForm = () => {
    setIsOpenCreditForm(true);
    setBalanceReal(creditReal);
    setBalanceFun(creditFun);
    setNickname(userName);
  };

  return (
    <>
      <Header
        isAuth={isAuth}
        creditFun={creditFun}
        creditReal={creditReal}
        handleLogout={handleLogout}
        userName={userName}
        closeAdminPanel={closeAdminPanel}
        handleClickOpenCreditForm={handleClickOpenCreditForm}
        currencyIcon={currencyIcon}
        currency={currency}
        role={role}
        permissions={permissions}
        currentPage={currentPage}
      ></Header>
      <CreditForm
        isOpenCreditForm={isOpenCreditForm}
        setIsOpenCreditForm={setIsOpenCreditForm}
        balanceReal={balanceReal}
        balanceFun={balanceFun}
        nickname={nickname}
        currency={currency}
      ></CreditForm>
    </>
  );
};
