export const ENVIRONMENT = {
    DEV: 'dev',
    STAGE: 'stage',
    CERT: 'cert',
}

export const MODE = {
    DEMO: 'DEMO',
    REAL: 'REAL',
    FUN: 'FUN',
}

export const LANGUAGE = {
    EN: 'EN',
    IT: 'IT',
}

export const CURRENCY = {
    FUN: 'FUN',
    EUR: 'EUR',
    RUB: 'RUB',
    USD: 'USD',
}

export const SERVICE = {
    DMS: 'dms',
    LCS: 'lcs',
}
