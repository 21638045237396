import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch } from '@mui/material'
import { CURRENCY, ENVIRONMENT, LANGUAGE, SERVICE } from 'consts/ApiParametrs'

import PropTypes from 'prop-types'

import styles from './filterPanel.module.scss'

export const FilterPanel = ({
    isOnlyWorkingGames,
    handleStatusGame,
    isOnlyClassesA,
    handleTypeClass,
    typeSortingByClass,
    handleSorting,
    service,
    handleChangeService,
    environment,
    handleChangeEnvironment,
    language,
    handleChangeLanguage,
    currency,
    handleChangeCurrency,
    isLoading,
    isAdmin,
}) => {
    return (
        <div className={styles.filterPanel}>
            <FormGroup className={styles.switchers}>
                <FormControlLabel
                    control={<Switch checked={isOnlyWorkingGames} onChange={handleStatusGame} />}
                    label="Only working games"
                    labelPlacement="start"
                    disabled={isLoading}
                />

                <FormControlLabel
                    control={<Switch checked={isOnlyClassesA} onChange={handleTypeClass} />}
                    label="Only classes <A>"
                    labelPlacement="start"
                    disabled={isLoading}
                />
            </FormGroup>

            <div className={styles.selectGroup}>
                <FormControl className={styles.select} variant="standard" disabled={isLoading}>
                    <InputLabel htmlFor="sorting-label">Sorting by class</InputLabel>
                    <Select
                        inputProps={{ id: 'sorting-label' }}
                        value={typeSortingByClass}
                        onChange={handleSorting}
                        label="Sorting by class"
                    >
                        <MenuItem value={'to-largest'}>Game class A-Z</MenuItem>
                        <MenuItem value={'to-smallest'}>Game class Z-A</MenuItem>
                        {/* <MenuItem value={'new'}>Newest first</MenuItem>
            <MenuItem value={'old'}>Oldest first</MenuItem> */}
                    </Select>
                </FormControl>

                <FormControl className={styles.select} variant="standard" disabled={isLoading}>
                    <InputLabel htmlFor="service-label">Service</InputLabel>
                    <Select
                        inputProps={{ id: 'service-label' }}
                        label="Service"
                        value={service}
                        onChange={handleChangeService}
                    >
                        <MenuItem value={SERVICE.LCS}>LCS</MenuItem>
                        <MenuItem value={SERVICE.DMS}>DMS</MenuItem>
                    </Select>
                </FormControl>
                {isAdmin && (
                    <FormControl className={styles.select} variant="standard" disabled={isLoading}>
                        <InputLabel htmlFor="environment-label">Environment</InputLabel>
                        <Select
                            inputProps={{ id: 'environment-label' }}
                            label="Environment"
                            value={environment}
                            onChange={handleChangeEnvironment}
                        >
                            <MenuItem value={ENVIRONMENT.DEV}>dev</MenuItem>
                            <MenuItem value={ENVIRONMENT.STAGE}>stage</MenuItem>
                            <MenuItem value={ENVIRONMENT.CERT}>cert</MenuItem>
                        </Select>
                    </FormControl>
                )}
                <FormControl
                    className={styles.select}
                    variant="standard"
                    disabled={isLoading}
                    sx={{
                        mb: 5,
                        minWidth: 200,
                        mr: '20px',
                        '@media (max-width: 576px)': {
                            minWidth: 150,
                        },
                    }}
                >
                    <InputLabel htmlFor="language-label">Game language</InputLabel>
                    <Select
                        inputProps={{ id: 'language-label' }}
                        label="Game language"
                        value={language}
                        onChange={handleChangeLanguage}
                    >
                        <MenuItem value={LANGUAGE.EN}>EN</MenuItem>
                        <MenuItem value={LANGUAGE.IT}>IT</MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    className={styles.select}
                    variant="standard"
                    disabled={isLoading}
                    sx={{
                        mb: 5,
                        minWidth: 200,
                        mr: '20px',
                        '@media (max-width: 576px)': {
                            minWidth: 150,
                        },
                    }}
                >
                    <InputLabel htmlFor="currency-label">Currency</InputLabel>
                    <Select
                        inputProps={{ id: 'currency-label' }}
                        label="Currency"
                        value={currency}
                        onChange={handleChangeCurrency}
                    >
                        <MenuItem value={CURRENCY.FUN}>FUN</MenuItem>
                        <MenuItem value={CURRENCY.EUR}>EUR</MenuItem>
                        <MenuItem value={CURRENCY.RUB}>RUB</MenuItem>
                        <MenuItem value={CURRENCY.USD}>USD</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

FilterPanel.propTypes = {
    isOnlyWorkingGames: PropTypes.bool.isRequired,
    handleStatusGame: PropTypes.func.isRequired,
    isOnlyClassesA: PropTypes.bool.isRequired,
    handleTypeClass: PropTypes.func.isRequired,
    typeSortingByClass: PropTypes.string.isRequired,
    handleSorting: PropTypes.func.isRequired,
    service: PropTypes.string.isRequired,
    handleChangeService: PropTypes.func.isRequired,
    environment: PropTypes.string.isRequired,
    handleChangeEnvironment: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    handleChangeLanguage: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    handleChangeCurrency: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
}
