import { ADD_PLAYER, SET_PLAYERS } from './actionTypes';

export const setPlayers = (data) => ({
  type: SET_PLAYERS,
  payload: data,
});

export const addPlayer = (data) => ({
  type: ADD_PLAYER,
  payload: data,
});
