import { ENVIRONMENT, LANGUAGE, SERVICE } from 'consts/ApiParametrs';
import {
  SET_ENVIRONMENT,
  SET_FILTER_ONLY_CLASSES_A,
  SET_FILTER_WORKING_GAMES,
  SET_GAME_LANGUAGE,
  SET_GAME_SCREEN,
  SET_SERVICE,
  SET_SORTING_GAMES_BY_CLASS,
} from '../actions/actionTypes';

const initialState = {
  onlyWorkingGames: true,
  onlyClassesA: true,
  typeSortingByClass: 'to-largest',
  service: SERVICE.LCS,
  environment: ENVIRONMENT.DEV,
  gameLanguage: LANGUAGE.EN,
  fullScreenGame: true,
};

export const configUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_WORKING_GAMES:
      return {
        ...state,
        onlyWorkingGames: !state.onlyWorkingGames,
      };

    case SET_FILTER_ONLY_CLASSES_A:
      return {
        ...state,
        onlyClassesA: !state.onlyClassesA,
      };

    case SET_SORTING_GAMES_BY_CLASS:
      return {
        ...state,
        typeSortingByClass: action.payload,
      };

    case SET_SERVICE:
      return {
        ...state,
        service: action.payload,
      };

    case SET_ENVIRONMENT:
      return {
        ...state,
        environment: action.payload,
      };

    case SET_GAME_LANGUAGE:
      return {
        ...state,
        gameLanguage: action.payload,
      };

    case SET_GAME_SCREEN:
      return {
        ...state,
        fullScreenGame: !state.fullScreenGame,
      };

    default:
      return state;
  }
};
