import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoginContainer } from '../LoginContainer/LoginContainer';

export const LoginPage = () => {
  const isAuth = useSelector((state) => state.user?.isAuth);
  const role = useSelector((state) => state?.user?.player?.role?.name);

  if (role === 'demo' && isAuth) {
    return <Navigate to="/demo" />;
  } else if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div id="loginBg" className="loginBg">
      <LoginContainer />
    </div>
  );
};
