import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from './components/pages/loginPage/LoginPage/LoginPage';
import { MainPage } from './components/pages/MainPage/MainPage';
import { RequireAuth } from './components/hoc/RequireAuth';
import { useEffect, useState } from 'react';
import { Preloader } from 'components/Preloader/Preloader';
import { updatePlayerInfo } from 'redux/actions/setAuth';
import { AdminPage } from 'components/pages/adminPage/AdminPage';
import { DemoPage } from 'components/pages/demoPage/DemoPage';
import { RequirePermission } from 'components/hoc/RequirePermission';
import { MainLayout } from 'components/MainLayout/MainLayout';

function App() {
  const [isLoading, setIsLoading] = useState('true');
  const dispatch = useDispatch();

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      dispatch(updatePlayerInfo()).then(() => {
        setIsLoading(false);
      });
    }
  }, [token, dispatch]);

  if (token && isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route element={<RequireAuth allowedRoles={['admin', 'developer', 'certificator', 'stage_only']} />}>
              <Route index element={<MainPage />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={['admin', 'developer', 'certificator']} />}>
              <Route path="admin" element={<AdminPage />} />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>

          <Route element={<RequirePermission allowedPermissions={['demo_page']} />}>
            <Route path="/demo" element={<DemoPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
