import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { FormControl, Grid, InputLabel, List, ListSubheader, MenuItem, Select, TextField } from '@mui/material'
import { PlayCircleFilledOutlined } from '@mui/icons-material'

import styles from './game.module.scss'
import { MODE, SERVICE } from 'consts/ApiParametrs'
import { gameVersionsSorting } from 'utils/gameVersionsSorting'

export const Game = (props) => {
    const {
        gameName: title,
        gameIconUrl,
        gameStatus,
        gameClass,
        runGame,
        selectedCurrency,
        currency,
        languages,
        selectedLanguage,
        versions,
        latestVersion,
        release,
        service,
    } = props

    const [isNotSupportedCurrency, setIsNotSupportedCurrency] = useState(false)
    const [isNotSupportedLanguage, setIsNotSupportedLanguage] = useState(false)
    const [isOpenLogInfo, setIsOpenLogInfo] = useState(false)
    const [version, setVersion] = useState('')

    useEffect(() => {
        const selectedVersion = latestVersion || (release && release.tag)
        if (selectedVersion) {
            setVersion(selectedVersion)
        }
    }, [latestVersion, release])

    useEffect(() => {
        if (currency) {
            setIsNotSupportedCurrency(!currency.includes(selectedCurrency))
        }
    }, [selectedCurrency, currency])

    useEffect(() => {
        if (languages) {
            setIsNotSupportedLanguage(!languages.includes(selectedLanguage))
        }
    }, [selectedLanguage, languages])

    let poster

    switch (gameStatus) {
        case 'OK':
            poster = gameIconUrl
            break

        case 'GAME_NOT_CONFIGURED':
            poster = '/img/gameNotConfigured.png'
            break

        case 'ASSETS_NOT_INSTALLED':
            poster = '/img/assetsNotInstalled.png'
            break

        case 'MATH_NOT_INSTALLED':
            poster = '/img/mathNotInstalled.png'
            break

        default:
            break
    }

    const isDmsService = service === SERVICE.DMS

    const handleChangeVersionsGame = (event) => {
        setVersion(event.target.value)
    }

    if (Array.isArray(versions)) {
        gameVersionsSorting(versions)
    }

    return (
        <Grid item xs={12} sm={6} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            {isOpenLogInfo ? (
                <Card className={styles.card}>
                    <div className={styles.topStripeHeader}></div>
                    <List
                        className={styles.listLogInfo}
                        sx={{
                            width: '100%',
                            position: 'relative',
                            overflow: 'auto',
                            height: '98%',
                            '& ul': { padding: 0 },
                        }}
                        subheader={<li />}
                    >
                        {versions.map((version) => (
                            <li key={`section-${version.name}`}>
                                <ul>
                                    <ListSubheader>
                                        <div className={styles.listLogInfoSubheader}>
                                            <div className={styles.listLogInfoVesrsionName}>{version.name}</div>
                                            <>{version.modified}</>
                                            <> {version.size}MB</>
                                        </div>
                                        <div className={styles.bottomStripeHeader}></div>
                                    </ListSubheader>
                                    {version.changeLog.map((log, id) => (
                                        <li className={styles.listLogItem} key={`${id}`}>
                                            {log}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </List>
                    <div className={styles.whiteCircleIcon}>
                        <CloseIcon
                            color="primary"
                            variant="contained"
                            onClick={() => setIsOpenLogInfo(false)}
                        ></CloseIcon>
                    </div>
                </Card>
            ) : (
                <Card className={styles.card}>
                    <CardMedia component="img" alt={`logo ${title}`} sx={{ height: '250px' }} image={poster} />
                    <div className={styles.controlPanel}>
                        <div className={styles.info}>
                            <CardContent className={styles.gameNameBlock}>
                                <Typography className={styles.title} gutterBottom variant="h5" component="div">
                                    {title}
                                </Typography>
                            </CardContent>

                            {versions ? (
                                <FormControl className={styles.selectGameVersion} size="small">
                                    <InputLabel htmlFor={`${gameClass} ${version}`} className={styles.versionLabel}>
                                        Game version
                                    </InputLabel>
                                    <Select
                                        inputProps={{ id: `${gameClass} ${version}` }}
                                        value={version}
                                        label="Game version"
                                        onChange={handleChangeVersionsGame}
                                    >
                                        {versions.map((version) => {
                                            return (
                                                <MenuItem key={version.name} value={version.name}>
                                                    {version.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            ) : release ? (
                                <TextField
                                    className={styles.fieldGameVersion}
                                    size="small"
                                    id="outlined-basic"
                                    label="Game version"
                                    variant="outlined"
                                    value={version}
                                />
                            ) : null}
                        </div>
                        <div className={styles.gameClassAndLogsBtn}>
                            <div className={styles.gameClass}>Class: {gameClass}</div>
                            <Button
                                className={styles.logInfoBtn}
                                variant="outlined"
                                onClick={() => setIsOpenLogInfo(true)}
                                disabled={gameStatus !== 'OK' || !versions}
                            >
                                changelog
                            </Button>
                        </div>

                        <CardActions
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: '10px',
                                mb: '15px',
                                padding: 0,
                                width: '95%',
                            }}
                        >
                            <Button
                                sx={{ width: '120px', height: '30px', padding: 0 }}
                                variant="outlined"
                                startIcon={<PlayCircleFilledOutlined />}
                                onClick={() => runGame({ mode: MODE.DEMO, gameClass, version })}
                                disabled={gameStatus !== 'OK' || isNotSupportedLanguage}
                            >
                                DEMO
                            </Button>
                            <Button
                                sx={{ width: '120px', height: '30px', padding: 0 }}
                                variant="outlined"
                                startIcon={<PlayCircleFilledOutlined />}
                                onClick={() => runGame({ mode: MODE.REAL, gameClass, version })}
                                disabled={gameStatus !== 'OK' || isNotSupportedCurrency || isNotSupportedLanguage}
                            >
                                REAL
                            </Button>
                            <Button
                                sx={{ width: '120px', height: '30px', padding: 0 }}
                                variant="outlined"
                                startIcon={<PlayCircleFilledOutlined />}
                                onClick={() => runGame({ mode: MODE.FUN, gameClass, version })}
                                disabled={
                                    gameStatus !== 'OK' ||
                                    isNotSupportedCurrency ||
                                    isNotSupportedLanguage ||
                                    isDmsService
                                }
                            >
                                FUN
                            </Button>
                        </CardActions>
                    </div>
                </Card>
            )}
        </Grid>
    )
}

Game.propTypes = {
    gameName: PropTypes.string,
    gameIconUrl: PropTypes.string,
    gameStatus: PropTypes.string.isRequired,
    gameClass: PropTypes.string.isRequired,
    runGame: PropTypes.func.isRequired,
    selectedCurrency: PropTypes.string.isRequired,
    currency: PropTypes.array,
    languages: PropTypes.array,
    selectedLanguage: PropTypes.string.isRequired,
    versions: PropTypes.array,
    release: PropTypes.object,
    service: PropTypes.string,
}
