import classNames from "classnames";
import PropTypes from "prop-types";

import { CardMedia, Grid } from "@mui/material";

import styles from "./demoGame.module.scss";
import { useEffect, useState } from "react";

function checkOrientationIsLandscape() {
  const { innerWidth, innerHeight } = window;
  return innerWidth > innerHeight;
}

export const DemoGame = ({ gameInfo, settings, runDemoGame }) => {
  const [isLandscape, setIslandscape] = useState(checkOrientationIsLandscape());
  useEffect(() => {
    function handleWindowResize() {
      setIslandscape(checkOrientationIsLandscape());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const countColumn = isLandscape ? 4 : 6;
  const { gameIconUrl } = gameInfo;
  const { backend, gameClass, version, launchEnv } = settings;
  let classs = classNames(styles.imageWrapper, styles.shine);
  return (
    <Grid item xs={12} sm={countColumn} sx={{ display: "flex", justifyContent: "center" }}>
      <CardMedia className={classs} onClick={() => runDemoGame({ backend, gameClass, version, launchEnv })}>
        <CardMedia sx={{ objectFit: "fill" }} component="img" alt={gameClass} image={gameIconUrl} />
      </CardMedia>
    </Grid>
  );
};

DemoGame.propTypes = {
  gameClass: PropTypes.string,
  gameIconUrl: PropTypes.string,
  runDemoGame: PropTypes.func,
};
