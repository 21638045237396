import PropTypes from 'prop-types';
import { useState } from 'react';

import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const Search = ({ handleSearch, isLoading }) => {
  const [value, setValue] = useState('');

  const updateValueSearch = (e) => {
    const valueSearch = e.target.value;
    setValue(valueSearch);
    handleSearch(valueSearch);
  };

  return (
    <TextField
      disabled={isLoading}
      label="Search game"
      value={value}
      onChange={(e) => updateValueSearch(e)}
      variant="standard"
      fullWidth
      sx={{
        mb: '1.5rem',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

Search.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
