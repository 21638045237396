import { Alert, Snackbar } from '@mui/material';
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { AdminPanel } from 'components/pages/adminPage/AdminPanel/AdminPanel';
import { DemoGamesTable } from 'components/pages/adminPage/demoGamesTable/DemoGamesTable';
import { CreditForm } from 'components/players/CreditForm';
import { PasswordResetForm } from 'components/players/PasswordResetForm';
import { PlayerForm } from 'components/players/PlayerForm.js';
import { PlayerRemoveDialog } from 'components/players/PlayerRemoveDialog';
import { PlayersTable } from 'components/players/PlayersTable/PlayersTable';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { setAdminPanel } from 'redux/actions/setAdminPanel';
import { setCurrentPage } from 'redux/actions/setCurrentUI';
import {
  addToDemoGamesDisplayed,
  changeEnvironmentDemoGame,
  changeServiceDemoGame,
  changeVersionDemoGame,
  removeFromDemoGamesDisplayed,
} from 'redux/actions/setDemoGames';
import GameService from 'services/GameService';
import PlayerService from 'services/PlayerService';
import { fetchDemoGames } from 'services/thunk/fetchDemoGames';
import { fetchDemoGamesControlled } from 'services/thunk/fetchDemoGamesControlled';
import { fetchPlayers } from 'services/thunk/fetchPlayers';

export const AdminContainer = () => {
  const dispatch = useDispatch();
  const players = useSelector((state) => state.players?.allPlayers);
  const currentPlayer = useSelector((state) => state.user?.player);
  const currencyIcon = useSelector((state) => state.currentSettings.currencyIcon);
  const currency = useSelector((state) => state.currentSettings.currency);
  const role = useSelector((state) => state.user?.player?.role?.name);

  const games = useSelector((state) => state.demoGames.games);
  const activeGames = useSelector((state) => state.demoGames.activeGames);
  const [openAlert, setOpenAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const [isOpenPlayerForm, setIsOpenPlayerForm] = useState(false);
  const [isOpenPasswordForm, setIsOpenPasswordForm] = useState(false);
  const [isOpenCreditForm, setIsOpenCreditForm] = useState(false);
  const [isOpenPlayerRemoveDialog, setIsOpenPlayerRemoveDialog] = useState(false);
  const [nicknameRemovePlayer, setNicknameRemovePlayer] = useState('');

  const [titlePlayerForm, setTitlePlayerForm] = useState('');
  const [formVariant, setFormVariant] = useState('');
  const [dataPlayer, setDataPlayer] = useState({});
  const [balanceReal, setBalanceReal] = useState('');
  const [balanceFun, setBalanceFun] = useState('');
  const [nickname, setNickname] = useState('');
  const [isShowPlayersTable, setIsShowPlayersTable] = useState(true);
  const [isShowDemoGamesTable, setIsShowDemoGamesTable] = useState(false);

  const isAdmin = role === 'admin';

  const removePlayer = async () => {
    setIsOpenPlayerRemoveDialog(false);
    try {
      await PlayerService.removePlayer({ nickname: nicknameRemovePlayer });
      dispatch(fetchPlayers());
      setOpenAlert(true);
      setSeverity('success');
      setResponseMessage('player successfully removed');
    } catch (error) {
      setOpenAlert(true);
      setSeverity('error');
      setResponseMessage(error.message);
      console.error(error.message);
    }
  };

  const changePlayer = async (nickname) => {
    let player;
    if (isAdmin) {
      player = players.find((player) => {
        return player.nickname === nickname;
      });
    } else {
      player = currentPlayer;
    }
    setDataPlayer({ ...player, role: player.role.name });
    setTitlePlayerForm('Edit player');
    setFormVariant('edit');
    setIsOpenPlayerForm(true);
  };

  const openPlayerForm = () => {
    setDataPlayer({});
    setTitlePlayerForm('Add new player');
    setFormVariant('add');
    setIsOpenPlayerForm(true);
  };

  const changePassword = async (nickname) => {
    let player;
    if (isAdmin) {
      player = players.find((player) => {
        return player.nickname === nickname;
      });
    } else {
      player = currentPlayer;
    }
    setDataPlayer({ ...player, role: player.role.name });
    setIsOpenPasswordForm(true);
  };

  const handleClickOpenCreditForm = (data) => {
    setIsOpenCreditForm(true);
    setBalanceReal(data.balance.real);
    setBalanceFun(data.balance.fun);
    setNickname(data.nickname);
  };

  const handleClickRemovePlayer = (nickname) => {
    setIsOpenPlayerRemoveDialog(true);
    setNicknameRemovePlayer(nickname);
  };

  useEffect(() => {
    dispatch(setCurrentPage('admin'));
    if (isAdmin) {
      dispatch(fetchPlayers());
      dispatch(fetchDemoGames());
      dispatch(fetchDemoGamesControlled());
    }
    dispatch(setAdminPanel(true));
  }, [dispatch, isAdmin]);

  const handleShowPlayers = () => {
    setIsShowPlayersTable(true);
    setIsShowDemoGamesTable(false);
  };

  const handleShowDemoGames = () => {
    setIsShowPlayersTable(false);
    setIsShowDemoGamesTable(true);
  };

  const changeVersionOfActiveGame = ({ version, gameClass }) => {
    dispatch(changeVersionDemoGame({ version, gameClass }));
  };

  const changeServiceOfActiveGame = ({ service, gameClass }) => {
    dispatch(changeServiceDemoGame({ service, gameClass }));
  };

  const changeEnvironmentOfActiveGame = ({ environment, gameClass }) => {
    dispatch(changeEnvironmentDemoGame({ environment, gameClass }));
  };

  const changeListActiveGames = ({ game, version, service, environment }) => {
    const gameClassActiveGames = activeGames.map((game) => game.settings.gameClass);
    const activeGame = {
      gameInfo: { ...game },
      settings: {
        gameClass: game.gameClass,
        backend: service,
        version,
        launchEnv: environment,
      },
    };
    const isActiveGame = gameClassActiveGames.includes(game.gameClass);
    if (isActiveGame) {
      dispatch(removeFromDemoGamesDisplayed(game.gameClass));
    } else {
      dispatch(addToDemoGamesDisplayed(activeGame));
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleClose = () => {
    setIsOpenCreditForm(false);
  };

  const saveNewDemoGameSet = async () => {
    const gamesSettings = activeGames.map((game) => game.settings);

    try {
      await GameService.postNewDemoGames({ gamesSettings });
      setOpenAlert(true);
      setSeverity('success');
      setResponseMessage('new set of demo games successfully created');
      handleClose();
    } catch (error) {
      setOpenAlert(true);
      setSeverity('error');
      setResponseMessage(error.message);
      console.error(error.message);
    }
  };

  return (
    <>
      <AdminPanel
        openPlayerForm={openPlayerForm}
        handleShowPlayers={handleShowPlayers}
        handleShowDemoGames={handleShowDemoGames}
        isAdmin={isAdmin}
      />
      {isShowPlayersTable && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {isAdmin ? (
            <PlayersTable
              players={players}
              handleClickOpenCreditForm={handleClickOpenCreditForm}
              handleClickRemovePlayer={handleClickRemovePlayer}
              changePlayer={changePlayer}
              changePassword={changePassword}
              removePlayer={removePlayer}
              currencyIcon={currencyIcon}
              currency={currency}
              openPlayerForm={openPlayerForm}
              isAdmin={isAdmin}
            ></PlayersTable>
          ) : (
            <PlayersTable
              players={[currentPlayer]}
              handleClickOpenCreditForm={handleClickOpenCreditForm}
              handleClickRemovePlayer={handleClickRemovePlayer}
              changePlayer={changePlayer}
              changePassword={changePassword}
              removePlayer={removePlayer}
              currencyIcon={currencyIcon}
              currency={currency}
              openPlayerForm={openPlayerForm}
              isAdmin={isAdmin}
            ></PlayersTable>
          )}
        </ErrorBoundary>
      )}
      {isShowDemoGamesTable && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <DemoGamesTable
            games={games}
            activeGames={activeGames}
            changeListActiveGames={changeListActiveGames}
            changeVersionOfActiveGame={changeVersionOfActiveGame}
            changeServiceOfActiveGame={changeServiceOfActiveGame}
            changeEnvironmentOfActiveGame={changeEnvironmentOfActiveGame}
            saveNewDemoGameSet={saveNewDemoGameSet}
          ></DemoGamesTable>
        </ErrorBoundary>
      )}
      <CreditForm
        isOpenCreditForm={isOpenCreditForm}
        setIsOpenCreditForm={setIsOpenCreditForm}
        balanceReal={balanceReal}
        balanceFun={balanceFun}
        nickname={nickname}
        currency={currency}
      ></CreditForm>
      <PlayerForm
        titlePlayerForm={titlePlayerForm}
        dataPlayer={dataPlayer}
        isOpenPlayerForm={isOpenPlayerForm}
        setIsOpenPlayerForm={setIsOpenPlayerForm}
        formVariant={formVariant}
        isAdmin={isAdmin}
      ></PlayerForm>
      <PasswordResetForm
        dataPlayer={dataPlayer}
        isOpenPasswordForm={isOpenPasswordForm}
        setIsOpenPasswordForm={setIsOpenPasswordForm}
        isAdmin={isAdmin}
      ></PasswordResetForm>
      <PlayerRemoveDialog
        nicknameRemovePlayer={nicknameRemovePlayer}
        isOpenPlayerRemoveDialog={isOpenPlayerRemoveDialog}
        setIsOpenPlayerRemoveDialog={setIsOpenPlayerRemoveDialog}
        removePlayer={removePlayer}
      ></PlayerRemoveDialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
          {responseMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
