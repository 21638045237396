import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PlayerService from 'services/PlayerService';
import { fetchPlayers } from 'services/thunk/fetchPlayers';

import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material';

import styles from './playerForm.module.scss';

export const PlayerForm = (props) => {
  const { isOpenPlayerForm, setIsOpenPlayerForm, titlePlayerForm, formVariant, isAdmin } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return props.dataPlayer;
    }, [props]),
  });

  useEffect(() => {
    if (props.dataPlayer.active) {
      setIsActive(props.dataPlayer.active);
    } else {
      setIsActive(false);
    }
  }, [props.dataPlayer.active]);

  useEffect(() => {
    reset(props.dataPlayer);
  }, [props.dataPlayer, reset]);

  const handleClose = () => {
    setIsOpenPlayerForm(false);
  };

  const handleStatusActive = (event) => {
    setIsActive(event.target.checked);
  };

  const onSubmitAddingPlayer = async (data) => {
    try {
      await PlayerService.addPlayer(data);
      dispatch(fetchPlayers());
      setOpenAlert(true);
      setSeverity('success');
      setResponseMessage('player successfully added');
      handleClose();
      reset();
    } catch (error) {
      setOpenAlert(true);
      setSeverity('error');
      setResponseMessage(error.message);
      console.error(error.message);
    }
  };

  const onSubmitEditingPlayer = async (data) => {
    try {
      await PlayerService.changePlayer(data);
      dispatch(fetchPlayers());
      setOpenAlert(true);
      setSeverity('success');
      setResponseMessage('player successfully changed');
      handleClose();
      reset();
    } catch (error) {
      setOpenAlert(true);
      setSeverity('error');
      setResponseMessage(error.message);
      console.error(error.message);
    }
  };

  return (
    <>
      <Dialog open={isOpenPlayerForm} onClose={handleClose}>
        <DialogTitle className={styles.playerDialogTitle}>{titlePlayerForm}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(formVariant === 'add' ? onSubmitAddingPlayer : onSubmitEditingPlayer)}>
            <div className={styles.playerForm}>
              <div className={styles.fieldForm}>
                <TextField
                  label="Nickname"
                  variant="standard"
                  className={styles.playerFormInput}
                  placeholder="Nickname"
                  autoFocus
                  {...register('nickname', { required: 'Reqiured field' })}
                  error={!!errors.nickname}
                  helperText={errors?.nickname ? errors.nickname.message : null}
                  disabled={formVariant !== 'add'}
                />
              </div>

              {formVariant === 'add' && (
                <div className={styles.fieldForm}>
                  <TextField
                    label="Password"
                    variant="standard"
                    className={styles.playerFormInput}
                    placeholder="Password"
                    {...register('password', { required: 'Reqiured field' })}
                    error={!!errors.password}
                    helperText={errors?.password ? errors.password.message : null}
                  />
                </div>
              )}

              <div className={styles.fieldForm}>
                <TextField
                  label="First name"
                  variant="standard"
                  className={styles.playerFormInput}
                  placeholder="First name"
                  {...register('firstName', { required: 'Reqiured field' })}
                  error={!!errors.firstName}
                  helperText={errors?.firstName ? errors.firstName.message : null}
                />
              </div>

              <div className={styles.fieldForm}>
                <TextField
                  label="Last name"
                  variant="standard"
                  className={styles.playerFormInput}
                  placeholder="Last name"
                  {...register('lastName', { required: 'Reqiured field' })}
                  error={!!errors.lastName}
                  helperText={errors?.lastName ? errors.lastName.message : null}
                />
              </div>

              <div className={styles.fieldForm}>
                <TextField
                  label="Email"
                  variant="standard"
                  className={styles.playerFormInput}
                  placeholder="Email"
                  {...register('email', { required: 'Reqiured field' })}
                  error={!!errors.email}
                  helperText={errors?.email ? errors.email.message : null}
                />
              </div>

              <div className={styles.fieldForm}>
                <TextField
                  label="Company"
                  variant="standard"
                  className={styles.playerFormInput}
                  placeholder="Company"
                  {...register('company', { required: 'Reqiured field' })}
                  error={!!errors.company}
                  helperText={errors?.company ? errors.company.message : null}
                />
              </div>
              {isAdmin && (
                <div className={styles.fieldForm}>
                  <TextField
                    sx={{ width: '200px' }}
                    select
                    label="Role"
                    variant="standard"
                    className={styles.playerFormInput}
                    defaultValue={props.dataPlayer.role ? props.dataPlayer.role : ''}
                    {...register('role', { required: 'Reqiured field' })}
                    error={!!errors.role}
                    helperText={errors?.role ? errors.role.message : null}
                    disabled={!isAdmin}
                  >
                    <MenuItem value={'admin'}>admin</MenuItem>
                    <MenuItem value={'developer'}>developer</MenuItem>
                    <MenuItem value={'certificator'}>certificator</MenuItem>
                    <MenuItem value={'demo'}>demo</MenuItem>
                  </TextField>
                </div>
              )}
            </div>
            {isAdmin && (
              <FormControlLabel
                sx={{
                  width: '210px',
                  margin: 0,
                  marginTop: '20px',
                  justifyContent: 'space-between',
                }}
                control={<Checkbox checked={isActive} onChange={handleStatusActive} />}
                label="Active"
                labelPlacement="start"
                {...register('active')}
              />
            )}

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {formVariant === 'add' ? <Button type="submit">ADD</Button> : <Button type="submit">EDIT</Button>}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
          {responseMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

PlayerForm.propTypes = {
  isOpenPlayerForm: PropTypes.bool.isRequired,
  setIsOpenPlayerForm: PropTypes.func.isRequired,
  titlePlayerForm: PropTypes.string.isRequired,
  formVariant: PropTypes.string.isRequired,
};
