import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from 'redux/actions/setAuth';
import { LoginForm } from '../LoginForm/LoginForm';

export const LoginContainer = () => {
  const dispatch = useDispatch();

  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  const handleLogIn = (e) => {
    e.preventDefault();
    dispatch(login(loginValue, passwordValue));
  };

  return (
    <LoginForm
      loginValue={loginValue}
      setLoginValue={setLoginValue}
      passwordValue={passwordValue}
      setPasswordValue={setPasswordValue}
      handleLogIn={handleLogIn}
    ></LoginForm>
  );
};
