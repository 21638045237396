import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import styles from './loginForm.module.scss';

export const LoginForm = ({ loginValue, setLoginValue, passwordValue, setPasswordValue, handleLogIn }) => {
  return (
    <form className={styles.loginForm} onSubmit={handleLogIn}>
      <h2>Please login</h2>
      <input
        onChange={(event) => setLoginValue(event.target.value)}
        value={loginValue}
        type="text"
        placeholder="login"
        autoComplete="on"
      />
      <input
        onChange={(event) => setPasswordValue(event.target.value)}
        value={passwordValue}
        type="password"
        placeholder="password"
        autoComplete="on"
      />
      <div>
        <Button variant="contained" type="submit">
          LOGIN
        </Button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  loginValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setLoginValue: PropTypes.func.isRequired,
  passwordValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setPasswordValue: PropTypes.func.isRequired,
  handleLogIn: PropTypes.func.isRequired,
};
