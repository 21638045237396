import { setGames } from 'redux/actions/setGames';
import GameService from 'services/GameService';

export function fetchGames(dev) {
  return async (dispatch) => {
    try {
      const response = await GameService.getGames(dev);
      dispatch(setGames(response.data.data.games));
    } catch (error) {
      console.error(error.message);
    }
  };
}
