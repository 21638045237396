import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updatePlayerInfo } from 'redux/actions/setAuth'
import PlayerService from 'services/PlayerService'
import { fetchPlayers } from 'services/thunk/fetchPlayers'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material'

import styles from './creditForm.module.scss'
import { CURRENCY } from 'consts/ApiParametrs'

export const CreditForm = ({
    isOpenCreditForm,
    setIsOpenCreditForm,
    balanceReal,
    balanceFun,
    nickname,
    currency,
    sendMessageToIframe,
}) => {
    const [openAlert, setOpenAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [severity, setSeverity] = useState('success')
    const [valueReal, setValueReal] = useState('')
    const [valueFun, setValueFun] = useState('')
    const dispatch = useDispatch()

    let moneyFun = balanceFun
    let moneyReal = balanceReal

    if (currency !== CURRENCY.RUB) {
        moneyFun = (balanceFun / 100).toFixed(2)
        moneyReal = (balanceReal / 100).toFixed(2)
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenAlert(false)
    }

    useEffect(() => {
        setValueReal(moneyReal)
        setValueFun(moneyFun)
    }, [moneyReal, moneyFun, nickname])

    const handleClose = () => {
        setIsOpenCreditForm(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            let fun = valueFun
            let real = valueReal

            if (currency !== 'RUB') {
                fun = valueFun * 100
                real = valueReal * 100
            }

            await PlayerService.setBalance({
                nickname,
                real,
                fun,
            })
            dispatch(fetchPlayers())
            setOpenAlert(true)
            setSeverity('success')
            setResponseMessage('credit successfully changed')
            dispatch(updatePlayerInfo())

            handleClose()
            if (sendMessageToIframe) {
                sendMessageToIframe()
            }
        } catch (error) {
            setOpenAlert(true)
            setSeverity('error')
            setResponseMessage(error.message)
            console.error(error.message)
        }
    }

    return (
        <>
            <Dialog open={isOpenCreditForm} onClose={handleClose}>
                <DialogTitle className={styles.creditDialogTitle}>Change credit</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.creditForm}>
                            <div className={styles.fieldForm}>
                                <TextField
                                    label="Credit Real"
                                    variant="standard"
                                    className={styles.playerFormInput}
                                    placeholder="credit"
                                    autoFocus
                                    value={valueReal}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                                    onChange={(e) => setValueReal(e.target.value)}
                                />
                            </div>

                            <div className={styles.fieldForm}>
                                <TextField
                                    label="Credit Fun"
                                    variant="standard"
                                    className={styles.playeFormInput}
                                    placeholder="credit"
                                    value={valueFun}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                                    onChange={(e) => setValueFun(e.target.value)}
                                />
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit">CHANGE</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
                    {responseMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

CreditForm.propTypes = {
    isOpenCreditForm: PropTypes.bool.isRequired,
    setIsOpenCreditForm: PropTypes.func.isRequired,
    sendMessageToIframe: PropTypes.func,
    balanceReal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    balanceFun: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    nickname: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
}
