import {
  SET_FILTER_WORKING_GAMES,
  SET_FILTER_ONLY_CLASSES_A,
  SET_SORTING_GAMES_BY_CLASS,
  SET_SERVICE,
  SET_ENVIRONMENT,
  SET_GAME_LANGUAGE,
  SET_GAME_SCREEN,
} from './actionTypes';

export const setFilterWorkingGames = () => ({
  type: SET_FILTER_WORKING_GAMES,
});

export const setFilterOnlyClassesA = () => ({
  type: SET_FILTER_ONLY_CLASSES_A,
});

export const setSortingGamesByClass = (data) => ({
  type: SET_SORTING_GAMES_BY_CLASS,
  payload: data,
});

export const setService = (data) => ({
  type: SET_SERVICE,
  payload: data,
});

export const setEnvironment = (data) => ({
  type: SET_ENVIRONMENT,
  payload: data,
});

export const setGameLanguage = (data) => ({
  type: SET_GAME_LANGUAGE,
  payload: data,
});

export const setGameScreen = () => ({
  type: SET_GAME_SCREEN,
});
