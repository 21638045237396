import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import styles from './adminPanel.module.scss';
import { Fragment } from 'react';

export const AdminPanel = ({ openPlayerForm, handleShowPlayers, handleShowDemoGames, isAdmin }) => {
  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }} className={styles.adminPanel}>
        <AppBar position="static" elevation={0} sx={{ flexGrow: 1, backgroundColor: '#bbdefb' }}>
          <Toolbar className={styles.toolBar}>
            <Typography className={styles.title} variant="h6" component="div" color="primary">
              Admin panel
            </Typography>

            <div className={styles.buttonGroup}>
              {isAdmin && (
                <Button className={styles.button} variant="contained" sx={{ ml: '50px' }} onClick={handleShowPlayers}>
                  EDIT PLAYERS
                </Button>
              )}

              {isAdmin && (
                <Button className={styles.button} variant="contained" sx={{ ml: '50px' }} onClick={handleShowDemoGames}>
                  EDIT DEMO PAGE
                </Button>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </Fragment>
  );
};

AdminPanel.propTypes = {
  openPlayerForm: PropTypes.func.isRequired,
};
