import { setAllDemoGames } from 'redux/actions/setDemoGames';
import GameService from 'services/GameService';

export function fetchDemoGamesControlled() {
  return async (dispatch) => {
    try {
      const response = await GameService.getDemoGamesControlled();
      dispatch(setAllDemoGames(response.data.data));
    } catch (error) {
      console.error(error.message);
    }
  };
}
