import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const PlayerRemoveDialog = ({
  nicknameRemovePlayer,
  isOpenPlayerRemoveDialog,
  setIsOpenPlayerRemoveDialog,
  removePlayer,
}) => {
  const handleClose = () => {
    setIsOpenPlayerRemoveDialog(false);
  };

  return (
    <Dialog
      open={isOpenPlayerRemoveDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
        {'Remove player'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove {nicknameRemovePlayer}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={() => removePlayer()} autoFocus>
          remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PlayerRemoveDialog.propTypes = {
  nicknameRemovePlayer: PropTypes.string.isRequired,
  isOpenPlayerRemoveDialog: PropTypes.bool.isRequired,
  setIsOpenPlayerRemoveDialog: PropTypes.func.isRequired,
  removePlayer: PropTypes.func.isRequired,
};
