import { ADD_PLAYER, SET_PLAYERS } from '../actions/actionTypes';

const initialState = {
  allPlayers: [],
};

export const fetchPlayersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYERS:
      return {
        ...state,
        allPlayers: action.payload,
      };

    case ADD_PLAYER:
      return {
        ...state,
        allPlayers: [...state.allPlayers, action.payload],
      };

    default:
      return state;
  }
};
