import $api from 'utils/http';

export default class GameService {
  static async getGameURL({ environment, gameClass, mode, language, currency, version, service, launchEnv }) {
    return $api.get(
      `${process.env.REACT_APP_API_URL}games/open?env=${environment}&gameClass=${gameClass}&mode=${mode}&currency=${currency}&language=${language}&version=${version}&backend=${service}&launchEnv=${launchEnv}`
    );
  }

  static async getGames(env = 'dev') {
    return $api.get(`${process.env.REACT_APP_API_URL}games/info?env=${env}`);
  }

  static async getDemoGames() {
    return $api.get(`${process.env.REACT_APP_API_URL}demo/games`);
  }

  static async getDemoGamesControlled() {
    return $api.get(`${process.env.REACT_APP_API_URL}admin/demo/games`);
  }

  static async postNewDemoGames(data) {
    return $api.post(`${process.env.REACT_APP_API_URL}admin/demo/games`, data);
  }
}
