import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const RequirePermission = ({ allowedPermissions }) => {
  const isAuth = useSelector((state) => state.user?.isAuth);
  const permissionsUser = useSelector((state) => state.user?.player?.role?.permissions);
  const location = useLocation();

  if (!isAuth) return <Navigate to="/login" />;

  const isAllow = permissionsUser.some((permission) => allowedPermissions.includes(permission));

  return isAllow ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};

RequirePermission.propTypes = {
  allowedPermissions: PropTypes.array.isRequired,
};
