import PropTypes from 'prop-types'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import styles from './iframe.module.scss'
import { Button } from '@mui/material'

export const Iframe = ({ URLGame, closeModalIframe, iframeRef, handleClickOpenCreditForm, currentPage }) => {
    return (
        <div className={styles.overlayFrameWrapper}>
            <div className={styles.overlayFrame}>
                <div className={styles.btnClose}>
                    <CloseRoundedIcon sx={{ width: '50px', height: '50px' }} onClick={() => closeModalIframe(false)} />
                </div>
                <div className={styles.iframe}>
                    <iframe
                        ref={iframeRef}
                        src={URLGame}
                        title="gameFrame"
                        style={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            border: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </div>
                {currentPage === 'main' && (
                    <Button
                        className={styles.updateCreditBtn}
                        variant="contained"
                        onClick={() => handleClickOpenCreditForm()}
                    >
                        Update credit
                    </Button>
                )}
            </div>
        </div>
    )
}

Iframe.propTypes = {
    URLGame: PropTypes.string.isRequired,
    closeModalIframe: PropTypes.func.isRequired,
    iframeRef: PropTypes.object,
    handleClickOpenCreditForm: PropTypes.func,
    currentPage: PropTypes.string,
}
