import { setPlayers } from 'redux/actions/setPlayers';
import PlayerService from 'services/PlayerService';

export function fetchPlayers() {
  return async (dispatch) => {
    try {
      const response = await PlayerService.getPlayers();
      dispatch(setPlayers(response.data.data.players));
    } catch (error) {
      console.error(error.message);
    }
  };
}
