import axios from 'axios';
import AuthService from 'services/AuthService';
import { LOGOUT, SET_USER } from './actionTypes';

export const logout = () => ({
  type: LOGOUT,
});

export function login(login, password) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}accounting/signin?nickname=${login}&password=${password}`
      );
      localStorage.setItem('token', response.data.security.access_token);
      localStorage.setItem('tokenR', response.data.security.refresh_token);
      dispatch({ type: SET_USER, payload: response.data });
    } catch (error) {
      alert('Please check your username and password and try logging in again');
      console.error(error.message);
    }
  };
}

export function updatePlayerInfo() {
  return async (dispatch) => {
    try {
      const response = await AuthService.auth();
      dispatch({ type: SET_USER, payload: response.data });
    } catch (error) {
      console.error(error.message);
    }
  };
}
