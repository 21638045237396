import {
  SET_ALL_DEMO_GAMES,
  SET_DEMO_GAMES,
  REMOVE_ACTIVE_GAME,
  ADD_ACTIVE_GAME,
  CHANGE_VERSION_DEMO_GAME,
  CHANGE_SERVICE_DEMO_GAME,
  CHANGE_ENVIRONMENT_DEMO_GAME,
} from './actionTypes';

export const setDemoGames = (data) => ({
  type: SET_DEMO_GAMES,
  payload: data,
});

export const setAllDemoGames = (data) => ({
  type: SET_ALL_DEMO_GAMES,
  payload: data,
});

export const addToDemoGamesDisplayed = (data) => ({
  type: ADD_ACTIVE_GAME,
  payload: data,
});

export const removeFromDemoGamesDisplayed = (data) => ({
  type: REMOVE_ACTIVE_GAME,
  payload: data,
});

export const changeVersionDemoGame = (data) => ({
  type: CHANGE_VERSION_DEMO_GAME,
  payload: data,
});

export const changeServiceDemoGame = (data) => ({
  type: CHANGE_SERVICE_DEMO_GAME,
  payload: data,
});

export const changeEnvironmentDemoGame = (data) => ({
  type: CHANGE_ENVIRONMENT_DEMO_GAME,
  payload: data,
});
