import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './GameSettingInterface.module.scss';

export const GameSettingInterface = ({ isLoading, isFullScreenGame, handleStatusFullScreen }) => {
  return (
    <FormGroup className={styles.formGroup}>
      <FormControlLabel
        control={<Switch checked={isFullScreenGame} onChange={handleStatusFullScreen} />}
        label="Full screen mode"
        labelPlacement="start"
        disabled={isLoading}
      />
    </FormGroup>
  );
};

GameSettingInterface.propTypes = {
  isLoading: PropTypes.bool,
  isFullScreenGame: PropTypes.bool,
  handleStatusFullScreen: PropTypes.func,
};
