export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const SET_GAME_URL = 'SET_GAME_URL';
export const SET_GAMES = 'SET_GAMES';
export const SET_DEMO_GAMES = 'SET_DEMO_GAMES';
export const SET_ALL_DEMO_GAMES = 'SET_ALL_DEMO_GAMES';
export const CHANGE_VERSION_DEMO_GAME = 'CHANGE_VERSION_DEMO_GAME';
export const CHANGE_SERVICE_DEMO_GAME = 'CHANGE_SERVICE_DEMO_GAME';
export const CHANGE_ENVIRONMENT_DEMO_GAME = 'CHANGE_ENVIRONMENT_DEMO_GAME';
export const ADD_ACTIVE_GAME = 'ADD_ACTIVE_GAME';
export const REMOVE_ACTIVE_GAME = 'REMOVE_ACTIVE_GAME';
export const SET_PLAYERS = 'SET_PLAYERS';
export const ADD_PLAYER = 'ADD_PLAYER';
export const SET_STATUS_ADMIN_PANEL = 'SET_STATUS_ADMIN_PANEL';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_FILTER_WORKING_GAMES = 'SET_FILTER_WORKING_GAMES';
export const SET_FILTER_ONLY_CLASSES_A = 'SET_FILTER_ONLY_CLASSES_A';
export const SET_SORTING_GAMES_BY_CLASS = 'SET_SORTING_GAMES_BY_CLASS';
export const SET_SERVICE = 'SET_SERVICE';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_GAME_LANGUAGE = 'SET_GAME_LANGUAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_GAME_SCREEN = 'SET_GAME_SCREEN';
