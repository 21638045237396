import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { AdminContainer } from '../AdminContainer/AdminContainer';

export const AdminPage = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AdminContainer />
    </ErrorBoundary>
  );
};
