import { combineReducers } from 'redux'
import { gameUrlReducer } from './gameUrlReducer'
import { gamesReducer } from './gamesReducer'
import { userReducer } from './userReducer'
import { fetchPlayersReducer } from './fetchPlayersReducer'
import { adminReducer } from './adminReducer'
import { currentSettingsReducer } from './currentSettingsReducer'
import { configUIReducer } from './configUIReducer'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { demoGamesReducer } from './demoGamesReducer'
import { currentStateUIReducer } from './currentStateUIReducer'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['user', 'currentGame', 'games', 'players', 'adminPanel', 'currentStateUI'],
}

const reducers = {
    user: userReducer,
    currentGame: gameUrlReducer,
    games: gamesReducer,
    demoGames: demoGamesReducer,
    players: fetchPlayersReducer,
    adminPanel: adminReducer,
    currentSettings: currentSettingsReducer,
    stateUI: configUIReducer,
    currentStateUI: currentStateUIReducer,
}

const mainReducer = combineReducers(reducers)

const persistedReducer = persistReducer(persistConfig, mainReducer)

export default persistedReducer
