import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { fetchDemoGames } from 'services/thunk/fetchDemoGames'
import { fetchUrlGame } from 'services/thunk/fetchUrlGame'
import { setGameUrl } from 'redux/actions/setGame'
import { Iframe } from 'components/Iframe'
import { setCurrentPage } from 'redux/actions/setCurrentUI'
import { Preloader } from 'components/Preloader/Preloader'
import { DemoGames } from './DemoGames/DemoGames'

export const DemoGamesContainer = () => {
    const dispatch = useDispatch()
    const games = useSelector((state) => state.demoGames.displayedDemoGames)
    const URLGame = useSelector((state) => state.currentGame.URL)
    const [isActiveIframe, setIsActiveIframe] = useState(false)

    useEffect(() => {
        dispatch(setCurrentPage('demo'))
        dispatch(fetchDemoGames(games))
    }, [dispatch, games])

    useEffect(() => {
        if (isMobile && URLGame) {
            window.location.replace(URLGame)
        } else if (URLGame) {
            setIsActiveIframe(true)
        }
    }, [URLGame])

    const closeModalIframe = (status) => {
        setIsActiveIframe(status)
        dispatch(setGameUrl(''))
    }

    const runExternalDemoGame = ({ URL }) => {
        dispatch(setGameUrl(URL))
    }

    const runDemoGame = ({ backend, gameClass, version, launchEnv }) => {
        dispatch(
            fetchUrlGame({
                environment: 'demo_page',
                gameClass,
                mode: 'DEMO',
                language: 'EN',
                version,
                service: backend,
                launchEnv,
            })
        )
    }

    if (games.length === 0) {
        return <Preloader />
    }

    return (
        <>
            <DemoGames games={games} runDemoGame={runDemoGame} runExternalDemoGame={runExternalDemoGame} />
            {URLGame && isActiveIframe && <Iframe URLGame={URLGame} closeModalIframe={closeModalIframe} />}
        </>
    )
}
