import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const RequireAuth = ({ allowedRoles }) => {
  const isAuth = useSelector((state) => state.user?.isAuth);
  const role = useSelector((state) => state?.user?.player?.role?.name);
  const location = useLocation();

  if (!isAuth) return <Navigate to="/login" />;

  return allowedRoles.includes(role) ? (
    <Outlet />
  ) : role === 'demo' ? (
    <Navigate to="/demo" state={{ from: location }} />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

RequireAuth.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
};
